"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArrowIconButton = exports.ArrowButtonWrapper = exports.Header = exports.TableSearchWrapper = exports.ButtonsWrapper = exports.Link = exports.LinkWrapper = exports.TopBar = exports.ARROW_BUTTON_WIDTH = exports.TOP_BAR_HEIGHT = void 0;
const react_router_dom_1 = require("react-router-dom");
const material_1 = require("@mui/material");
const styled_components_1 = __importStar(require("styled-components"));
const mediaQuery_enum_1 = require("../../core/mediaQuery.enum");
exports.TOP_BAR_HEIGHT = 55;
exports.ARROW_BUTTON_WIDTH = 30;
exports.TopBar = styled_components_1.default.div(({ gridTemplateColumns }) => (0, styled_components_1.css) `
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: ${gridTemplateColumns};
  `);
exports.LinkWrapper = (0, styled_components_1.default)(material_1.Typography)(({ $isActive, theme: { palette } }) => (0, styled_components_1.css) `
    padding: 12px 16px;
    border-bottom: 3px solid ${$isActive ? palette.primary.main : palette.grey[300]};
    height: 100%;
  `);
exports.Link = (0, styled_components_1.default)(react_router_dom_1.Link)(({ $width }) => (0, styled_components_1.css) `
    text-decoration: none;
    color: inherit;
    height: 100%;
    min-height: ${exports.TOP_BAR_HEIGHT}px;
    width: ${$width ? $width : undefined};
    text-align: center;
  `);
exports.ButtonsWrapper = styled_components_1.default.div(({ theme: { palette }, $fullWidth }) => (0, styled_components_1.css) `
    display: flex;
    justify-content: flex-end;
    border-bottom: 3px solid ${palette.grey[300]};
    width: ${$fullWidth ? '100%' : undefined};
    height: 100%;
    display: flex;
    gap: 16px;

    @media (min-width: ${mediaQuery_enum_1.MediaQuery.MEDIUM}px) {
      padding-bottom: 12px;
      min-height: ${exports.TOP_BAR_HEIGHT}px;
    }
  `);
exports.TableSearchWrapper = styled_components_1.default.div(({ theme: { palette } }) => (0, styled_components_1.css) `
    display: grid;
    justify-items: end;
    padding: 0px 16px 12px;
    border-bottom: 3px solid ${palette.grey[300]};
    height: ${exports.TOP_BAR_HEIGHT}px;
  `);
exports.Header = (0, styled_components_1.default)(material_1.Typography)(({ $isActive, $isCentered, theme: { palette } }) => (0, styled_components_1.css) `
    padding: 12px 4px;
    border-bottom: 3px solid ${$isActive ? palette.primary.main : palette.grey[300]};
    height: 100%;

    ${$isCentered &&
    (0, styled_components_1.css) `
      display: flex;
      align-items: center;
      padding: 0 16px;
    `}
  `);
exports.ArrowButtonWrapper = styled_components_1.default.div(({ theme: { palette } }) => (0, styled_components_1.css) `
    height: 100%;
    border-bottom: 3px solid ${palette.grey[300]};
    width: ${exports.ARROW_BUTTON_WIDTH}px;
    margin: 0;
  `);
exports.ArrowIconButton = (0, styled_components_1.default)(material_1.IconButton) `
  margin-top: 6px;
  padding: 3px !important;
`;
