import { useEffect, useState } from 'react';
import { useQueryClient } from 'shared-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import { QueryKeyEnum } from 'core/global.enum';
import { PaginatedResponse } from 'core/global.types';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';
import { Server } from 'api/servers/servers.types';
import { addSequence, searchCardA, searchCardB } from 'api/sequences/sequences';
import { AddSequenceBody } from 'api/sequences/sequences.types';

import { useAddSequenceValidation } from './AddSequence.validation';
import { AddSequenceFormBody } from './AddSequence.types';
import { AddSequence } from './AddSequence';

export const AddSequenceContainer = () => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();
  const { schema } = useAddSequenceValidation();
  const [selectedServerId, setSelectedServerId] = useState('');
  const [cardAQuery, setCardAQuery] = useState('');
  const [cardBQuery, setCardBQuery] = useState('');

  const servers = queryClient.getQueryData<PaginatedResponse<Server>>([QueryKeyEnum.SERVERS])?.data || [];

  useEffect(() => {
    servers.length && setSelectedServerId(servers[0].id);
  }, [servers]);

  const methods = useForm<AddSequenceFormBody>({
    defaultValues: {
      kartaA: [],
      kartaB: null,
    },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const { mutate: onAddSequence, isLoading } = useMutation(addSequence, {
    onSuccess: (_, { kartaA }) => {
      queryClient.invalidateQueries([QueryKeyEnum.SEQUENCES]);

      kartaA?.length > 1
        ? toast.dark(formatMessage({ id: AppMessages['sequences.add.success.multi'] }))
        : toast.dark(formatMessage({ id: AppMessages['sequences.add.success'] }));

      onModalClose();
    },
  });

  const selectedTkd = methods.watch('tkd');
  const sequenceType = methods.watch('typ');
  const cardsA = methods.watch('kartaA');

  const { data: cardsAResponse, isLoading: isLoadingCardsA } = useQuery(
    [QueryKeyEnum.CARD_SEARCH, cardAQuery, selectedTkd],
    searchCardA,
    {
      args: {
        query: cardAQuery,
        offset: 0,
        limit: 100,
        tkdId: selectedTkd,
        typ: sequenceType,
      },
      enabled: !!selectedTkd,
      onSuccess: (response) => {
        if (response.data.length === 0) {
          methods.setError('kartaA', { message: formatMessage({ id: AppMessages['sequences.add.card.error'] }) });
        }
      },
    },
  );

  const { data: cardsBResponse, isLoading: isLoadingCardsB } = useQuery(
    [QueryKeyEnum.CARD_SEARCH, cardBQuery, cardsA],
    searchCardB,
    {
      args: {
        offset: 0,
        limit: 100,
        query: cardBQuery,
        tkdId: selectedTkd,
        typ: sequenceType,
        kartaAIds: cardsA ? (typeof cardsA === 'string' ? cardsA : cardsA.map(({ value }) => value).join(',')) : null,
      },
      enabled: !!cardsA?.length,
      onSuccess: (response) => {
        if (response.data.length === 0) {
          methods.setError('kartaB', { message: formatMessage({ id: AppMessages['sequences.add.card.error'] }) });
        }
      },
    },
  );

  const onSubmit = (body: AddSequenceFormBody) => {
    onAddSequence({
      typ: body.typ,
      tkd: body.tkd,
      kartaA: typeof body.kartaA === 'string' ? [body.kartaA] : body.kartaA.map(({ value }) => value),
      kartaB: body.kartaB,
    } as AddSequenceBody);
  };

  useEffect(() => {
    methods.reset();
  }, [isModalOpen]);

  return (
    <FormProvider {...methods}>
      <AddSequence
        selectedServerId={selectedServerId}
        setSelectedServerId={setSelectedServerId}
        onSubmit={onSubmit}
        isModalOpen={isModalOpen}
        onModalOpen={onModalOpen}
        onModalClose={onModalClose}
        isLoading={isLoading}
        servers={servers}
        cardAQuery={cardAQuery}
        setCardAQuery={setCardAQuery}
        cardsA={cardsAResponse?.data || []}
        isLoadingCardsA={isLoadingCardsA}
        cardBQuery={cardBQuery}
        setCardBQuery={setCardBQuery}
        cardsB={cardsBResponse?.data || []}
        isLoadingCardsB={isLoadingCardsB}
      />
    </FormProvider>
  );
};
