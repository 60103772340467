import { useContext } from 'react';

import { TopBarPaginationContext } from '../context/topBarPagination/topBarPaginationContext/TopBarPaginationContext';

export const useTopBarPagination = () => {
  const context = useContext(TopBarPaginationContext);

  if (context === undefined) {
    throw new Error(
      'TopBarPaginationContext is unavailable, make sure you are using TopBarPaginationContextController',
    );
  }

  return context;
};
