import { ReactNode } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { TopBar } from 'shared-ui';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { AppRoute, AppRouteWithParams } from 'routing/AppRoute.enum';
import { AddPositionContainer } from '../positions/addPosition/AddPositionContainer';
import { AddEventExportFormatPageModeEnum } from '../eventsExportFormats/addEventExportFormat/AddEventExportFormat.enum';
import { AddTerminalsDisplayContainer } from '../display/addTerminalsDisplay/AddTerminalsDisplayContainer';
import { useQuery } from 'hooks/useQuery/useQuery';
import { fetchOrganizations } from 'api/organizations/organizations';
import { QueryKeyEnum } from 'core/global.enum';
import { getObjectInNestedObjectByKey } from 'helpers/getObjectInNestedObjectByKey';
import { OrganizationsTreeSelect } from 'ui/organizationsTreeSelect/OrganizationsTreeSelect';
import { TableSearchContainer } from 'reusable/tableSearch/TableSearchContainer';
import { AddTerminalsSequenceContainer } from '../sequences/addTerminalsSequence/AddTerminalsSequenceContainer';
import { DefinitionBarRoute } from '../hooks/useTopBarRoutes';
import { useTopBarPagination } from '../hooks/useTopBarPagination';

import { DefinitionsBarProps } from './DefinitionsBar.types';
import * as Styled from './DefinitionsBar.styles';

export const LINK_WIDTH = 135;
export const BAR_ELEMENTS_MIN_WIDTH = 550;

export const DefinitionsBar = ({
  setPositionsTableSearchQuery,
  searchOrganizationId,
  setSearchOrganizationId,
}: DefinitionsBarProps) => {
  const { formatMessage } = useLocale();
  const location = useLocation();
  const navigate = useNavigate();

  const { data: organizationResponse } = useQuery([QueryKeyEnum.ORGANIZATIONS], () => fetchOrganizations());

  const organization = organizationResponse ? organizationResponse[organizationResponse.length - 1] : undefined;
  const selectedOrganization =
    organization && searchOrganizationId
      ? getObjectInNestedObjectByKey(organization, 'id', searchOrganizationId)
      : undefined;

  const currentPageBarComponentsDefinition: Record<DefinitionBarRoute['path'], ReactNode> = {
    [AppRoute.definitions]: (
      <TopBar.TableSearchWrapper>
        <OrganizationsTreeSelect
          isSearchType
          onChange={(val) => setSearchOrganizationId && setSearchOrganizationId(val?.id)}
          value={selectedOrganization ? selectedOrganization.object : undefined}
          placeholder={formatMessage({ id: AppMessages['definitions.organization.search'] })}
        />
      </TopBar.TableSearchWrapper>
    ),
    [AppRoute.positions]: (
      <>
        {setPositionsTableSearchQuery && (
          <TopBar.Search>
            <TableSearchContainer
              searchIconTestId="page-search"
              inputPlaceholder={formatMessage({ id: AppMessages['definitions.positions.search'] })}
              searchBy="positions"
              setTableSearchQuery={setPositionsTableSearchQuery}
            />
          </TopBar.Search>
        )}
        <TopBar.ButtonsWrapper>
          <AddPositionContainer />
        </TopBar.ButtonsWrapper>
      </>
    ),
    [AppRoute.eventsExportFormats]: (
      <TopBar.Button
        style={{ width: 250 }}
        onClick={() =>
          navigate(AppRouteWithParams.eventsExportFormats.get({ mode: AddEventExportFormatPageModeEnum.ADD }))
        }
        variant="contained"
        color="primary"
      >
        {formatMessage({ id: AppMessages['definitions.events.addExportFormat.title'] })}
      </TopBar.Button>
    ),
    [AppRoute.ownersControl]: <TopBar.EmptyBarWithLine />,
    [AppRoute.display]: (
      <TopBar.ButtonsWrapper>
        <AddTerminalsDisplayContainer />
      </TopBar.ButtonsWrapper>
    ),
    [AppRoute.logotype]: <TopBar.EmptyBarWithLine />,
    [AppRoute.sequencesTerminals]: (
      <TopBar.ButtonsWrapper>
        <AddTerminalsSequenceContainer />
      </TopBar.ButtonsWrapper>
    ),
  };

  const currentPageBarComponents = currentPageBarComponentsDefinition[location.pathname as DefinitionBarRoute['path']];

  const { currentPageRoutes, isLeftArrowVisible, isRightArrowVisible, onClickLeftArrow, onClickRightArrow } =
    useTopBarPagination();

  return (
    <>
      <Styled.TopBarWrapper>
        <Styled.ElementsWrapper>
          {isLeftArrowVisible && <TopBar.LeftArrowButton onClick={onClickLeftArrow} />}

          {currentPageRoutes.map((route) => (
            <TopBar.Link
              key={route.path}
              width={`${LINK_WIDTH}px`}
              linkTo={route.path}
              isActive={location.pathname === route.path}
            >
              {route.label}
            </TopBar.Link>
          ))}

          {isRightArrowVisible && <TopBar.RightArrowButton onClick={onClickRightArrow} />}
        </Styled.ElementsWrapper>

        <Styled.ExtraBarElements>
          <TopBar.EmptyBarWithLine fullWidth />

          <Styled.ElementsWrapper>{currentPageBarComponents}</Styled.ElementsWrapper>
        </Styled.ExtraBarElements>
      </Styled.TopBarWrapper>
    </>
  );
};
