import { Controller, useFormContext } from 'react-hook-form';
import { Modal, Button, Select, AutocompleteOption, Option, Autocomplete } from 'shared-ui';
import { debounce } from 'debounce';
import { useEffect } from 'react';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { MultiAutocomplete } from 'ui/multiAutocomplete/MultiAutocomplete';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';
import { Form } from 'ui/form/Form';
import { SequenceTooltipContent } from 'reusable/sequenceTooltipContent/SequenceTooltipContent';
import { FormAutocompleteContainer } from 'reusable/formAutocomplete/FormAutocompleteContainer';
import { QueryKeyEnum } from 'core/global.enum';
import { fetchTerminals } from 'api/terminals/terminals';
import { GroupTerminalsPermissionsIdSortEnum } from 'context/query/queryContext/QueryContext.enum';
import { Card } from 'api/cards/cards.types';
import { SequenceTypeEnum } from 'api/sequences/sequences.enum';

import { AddSequenceFormBody, AddSequenceProps } from './AddSequence.types';
import * as Styled from './AddSequence.styles';

export const AddSequence = ({
  onModalOpen,
  onModalClose,
  isLoading,
  onSubmit,
  isModalOpen,
  selectedServerId,
  setSelectedServerId,
  servers,
  cardAQuery,
  setCardAQuery,
  cardsA,
  isLoadingCardsA,
  cardsB,
  cardBQuery,
  setCardBQuery,
  isLoadingCardsB,
}: AddSequenceProps) => {
  const { formatMessage } = useLocale();
  const { serverOptions, sequenceTypeOptions } = useAppSelectOptions({ servers });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    setValue,
    setError,
    getFieldState,
    clearErrors,
  } = useFormContext<AddSequenceFormBody>();

  const selectedTkd = watch('tkd');
  const isTkdNotSelected = !selectedTkd?.length;
  const tkdErrorMessage = getFieldState('tkd').error?.message;
  const cardA = watch('kartaA') || '';
  const cardB = watch('kartaB');
  const sequenceType = watch('typ');
  const isTypeNotSelected = !sequenceType?.length;
  const isCardANotSelected = !cardA?.length;
  const isCardBSelected = !!cardB?.length;
  const cardAErrorMessage = getFieldState('kartaA').error?.message;
  const cardBErrorMessage = getFieldState('kartaB').error?.message;

  const getCardsOptions = (cards: Card[]) => {
    return cards.map((card) => ({
      label: card.numerKarty,
      value: card.id,
    }));
  };

  const handleCardASearch = debounce((search: string) => {
    setCardAQuery(search);
  }, 300);

  const handleCardBSearch = debounce((search: string) => {
    setCardBQuery(search);
  }, 300);

  const cardsBOptions = getCardsOptions(cardsB);
  const cardsAOptions = getCardsOptions(cardsA);

  useEffect(() => {
    setValue('kartaA', []);
    setValue('tkd', null);
  }, [sequenceType]);

  useEffect(() => {
    setValue('kartaA', []);
    clearErrors('kartaA');
    setCardAQuery('');
  }, [selectedTkd]);

  useEffect(() => {
    setValue('kartaB', null);
    clearErrors('kartaB');
  }, [cardA.length]);

  return (
    <>
      <Button onClick={onModalOpen} variant="contained">
        {formatMessage({ id: AppMessages['sequences.add.title'] })}
      </Button>

      <Modal
        header={formatMessage({ id: AppMessages['sequences.add.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        type="form"
        onSubmit={handleSubmit(onSubmit)}
        submitButtonDisabled={!isValid || isTkdNotSelected || isLoading || !!Object.keys(errors).length}
      >
        <Form.Grid>
          <Controller
            name="typ"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Select
                {...field}
                label={formatMessage({ id: AppMessages['sequences.add.type.label'] })}
                placeholder={formatMessage({ id: AppMessages['sequences.add.type.placeholder'] })}
                options={sequenceTypeOptions}
                required
                errorMessage={error?.message}
                withHelperText
                tooltipContent={<SequenceTooltipContent />}
              />
            )}
          />
          <Select
            value={selectedServerId}
            disabled={isTypeNotSelected}
            required
            onChange={(e) => {
              setValue('tkd', '');
              setSelectedServerId(e.target.value as string);
            }}
            label={formatMessage({ id: AppMessages['sequences.add.server.label'] })}
            placeholder={formatMessage({ id: AppMessages['sequences.add.server.placeholder'] })}
            withHelperText
            options={serverOptions}
          />
          <div>
            <FormAutocompleteContainer
              disabled={isTypeNotSelected}
              formFieldName="tkd"
              queryKey={QueryKeyEnum.TERMINALS}
              queryFunction={() => fetchTerminals(selectedServerId)}
              additionalKeys={selectedServerId && sequenceType ? [selectedServerId, sequenceType] : []}
              noDataCallback={() => {
                setError('tkd', { message: formatMessage({ id: AppMessages['sequences.add.terminal.error'] }) });
              }}
              args={{
                sort: [GroupTerminalsPermissionsIdSortEnum.ASC],
                zaakceptowane: true,
                sekwencjaTyp: sequenceType,
              }}
              createOption={(terminal) => {
                return terminal.id
                  ? {
                      label: terminal.nazwa,
                      value: terminal.id,
                      id: `${terminal.numerLogicznyKontrolera}`,
                    }
                  : (terminal as unknown as Option);
              }}
              handleOptionSelect={(option) => option.value}
              renderCustomOption={(props, option, terminals) => (
                <AutocompleteOption
                  props={props}
                  key={option.id}
                  idPrefix={formatMessage({ id: AppMessages['sequences.add.terminal.id'] })}
                  id={
                    terminals
                      ? terminals.find((terminal) => terminal.id === option.value)?.numerLogicznyKontrolera || 0
                      : ''
                  }
                  label={option.label}
                />
              )}
              label={formatMessage({ id: AppMessages['sequences.add.terminal.label'] })}
              placeholder={formatMessage({ id: AppMessages['sequences.add.terminal.placeholder'] })}
              required
              withPopupSearchIcon
            />
            <Styled.ErrorMessage>{tkdErrorMessage}</Styled.ErrorMessage>
          </div>

          <Controller
            name="kartaA"
            control={control}
            render={({ field }) => (
              <div>
                {sequenceType === SequenceTypeEnum.SKARBIEC ? (
                  <Autocomplete
                    {...field}
                    inputValue={cardAQuery}
                    disabled={isTypeNotSelected || isTkdNotSelected || isCardBSelected}
                    onChange={(_, option) => (option ? field.onChange(option.value) : field.onChange(''))}
                    value={cardsAOptions.find(({ value }) => (cardA as unknown as string) === value) || undefined}
                    options={cardsAOptions}
                    renderOption={(props, option) =>
                      option.value ? (
                        <li {...props} key={option.value}>
                          <Styled.OptionLabel> {option.label}</Styled.OptionLabel>
                        </li>
                      ) : null
                    }
                    loading={isLoadingCardsB}
                    loadingText={formatMessage({ id: AppMessages['common.loading'] })}
                    label={formatMessage({ id: AppMessages['sequences.add.cardA.label'] })}
                    noOptionsText={formatMessage({ id: AppMessages['common.notFound'] })}
                    onInputChange={(e, value) => {
                      e && e.type !== 'blur' && handleCardASearch(value);
                    }}
                    placeholder={formatMessage({ id: AppMessages['sequences.add.cardA.placeholder'] })}
                    required
                    withPopupSearchIcon
                  />
                ) : (
                  <MultiAutocomplete
                    {...field}
                    disabled={isTypeNotSelected || isTkdNotSelected || isCardBSelected}
                    stopRotatePopupIndicator
                    inputSize="big"
                    limitTags={1}
                    onChange={(_, option) => (option ? field.onChange(option) : field.onChange(null))}
                    onInputChange={(e, value) => {
                      e && handleCardASearch(value);
                    }}
                    value={Array.isArray(field.value) ? field.value : []}
                    options={cardsAOptions}
                    loading={isLoadingCardsA}
                    loadingText={formatMessage({ id: AppMessages['common.loading'] })}
                    label={formatMessage({ id: AppMessages['sequences.add.cardA.label'] })}
                    placeholder={formatMessage({
                      id: AppMessages['sequences.add.cardA.placeholder'],
                    })}
                    noOptionsText={formatMessage({ id: AppMessages['common.notFound'] })}
                    withLimitedTags
                    required
                  />
                )}
                {cardAErrorMessage?.startsWith('Brak') && (
                  <Styled.ErrorMessage>{cardAErrorMessage}</Styled.ErrorMessage>
                )}
                {!cardAErrorMessage && isCardBSelected && (
                  <Styled.InfoMessage>
                    {formatMessage({ id: AppMessages['sequences.add.cardA.blocked'] })}
                  </Styled.InfoMessage>
                )}
              </div>
            )}
          />

          <Controller
            name="kartaB"
            control={control}
            render={({ field }) => (
              <div>
                <Autocomplete
                  {...field}
                  disabled={isTypeNotSelected || isTkdNotSelected || isCardANotSelected}
                  onChange={(_, option) => (option ? field.onChange(option.value) : field.onChange(null))}
                  value={cardsBOptions.find(({ value }) => cardB === value) || null}
                  options={cardsBOptions}
                  renderOption={(props, option) =>
                    option.value ? (
                      <li {...props} key={option.value}>
                        <Styled.OptionLabel> {option.label}</Styled.OptionLabel>
                      </li>
                    ) : null
                  }
                  loading={isLoadingCardsB}
                  loadingText={formatMessage({ id: AppMessages['common.loading'] })}
                  label={formatMessage({ id: AppMessages['sequences.add.cardB.label'] })}
                  noOptionsText={formatMessage({ id: AppMessages['common.notFound'] })}
                  onInputChange={(e, value) => {
                    e && e.type !== 'blur' && handleCardBSearch(value);
                  }}
                  placeholder={formatMessage({ id: AppMessages['sequences.add.cardB.placeholder'] })}
                  required
                  withPopupSearchIcon
                />
                {cardBErrorMessage?.startsWith('Brak') && (
                  <Styled.ErrorMessage>{cardBErrorMessage}</Styled.ErrorMessage>
                )}
              </div>
            )}
          />
        </Form.Grid>
      </Modal>
    </>
  );
};
