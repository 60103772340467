import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';

export const OptionWrapper = styled.div`
  margin: -8px;
`;

export const OptionNumber = styled(Typography)(
  ({ theme: { palette } }) => css`
    color: ${palette.grey[900]};
    font-size: 12px;
  `,
);

export const OptionLabel = styled(Typography)(
  ({ theme: { palette } }) => css`
    color: ${palette.grey[900]};
    font-size: 14px;
    font-weight: 700;
  `,
);
