import styled from 'styled-components';
import { Button as ButtonBase } from 'shared-ui';

export const Wrapper = styled.div`
  width: 50%;
`;

export const Button = styled(ButtonBase)`
  width: 200px;
`;
