import { Definitions } from './Definitions';
import { TopBarPaginationContextController } from './context/topBarPagination/topBarPaginationContextController/TopBarPaginationContextController';

export const DefinitionsContainer = () => {
  return (
    <TopBarPaginationContextController>
      <Definitions />
    </TopBarPaginationContextController>
  );
};
