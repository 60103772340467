import { useEffect } from 'react';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { useQuery } from 'hooks/useQuery/useQuery';
import {
  QueryKeyLocalStorageEnum,
  SequencesCardASortEnum,
  SequencesIdSortEnum,
  SequencesQueryKeysEnum,
} from 'context/query/queryContext/QueryContext.enum';
import { useExpandedServerId } from 'hooks/useExpandedServerId/useExpandedServerId';
import { fetchSequences } from 'api/sequences/sequences';

import { SequencesTable } from './SequencesTable';
import { SequencesTableContainerProps } from './SequencesTable.types';

export const SequencesTableContainer = ({
  expandedServer,
  setSequencesCount,
  sequencesTableSearchQuery,
}: SequencesTableContainerProps) => {
  const serverId = expandedServer?.id || '';
  const {
    storedQueries: { [QueryKeyLocalStorageEnum.SEQUENCES]: sequencesQuery },
  } = useLocaleStoreQuery();
  const { setExpandedServerId } = useExpandedServerId();

  const { data: sequencesResponse } = useQuery(
    [QueryKeyEnum.SEQUENCES, serverId, sequencesQuery, sequencesTableSearchQuery],
    () => fetchSequences(serverId),
    {
      args: {
        ...sequencesQuery,
        [SequencesQueryKeysEnum.SORT]:
          sequencesQuery[SequencesQueryKeysEnum.SORT]?.[0] === SequencesIdSortEnum.ASC ||
          sequencesQuery[SequencesQueryKeysEnum.SORT]?.[0] === SequencesIdSortEnum.DESC
            ? [sequencesQuery[SequencesQueryKeysEnum.SORT]?.[0], SequencesCardASortEnum.ASC]
            : sequencesQuery[SequencesQueryKeysEnum.SORT],
        [SequencesQueryKeysEnum.QUERY]: sequencesTableSearchQuery,
      },
      enabled: !!expandedServer,
      onSuccess: (response) => {
        setSequencesCount(response.count);
      },
    },
  );

  useEffect(() => {
    setExpandedServerId(serverId);
  }, [serverId]);

  return (
    <SequencesTable
      expandedServer={expandedServer}
      sequencesResponse={sequencesResponse}
      sequencesTableSearchQuery={sequencesTableSearchQuery}
    />
  );
};
