import { Controller, useFormContext } from 'react-hook-form';
import { Modal } from 'shared-ui';

import { AddPositionBody } from 'api/positions/positions.types';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Input } from 'ui/input/Input';

import { AddPositionProps } from './AddPosition.types';
import * as Styled from './AddPosition.styles';

export const AddPosition = ({ onSubmit, isModalOpen, onModalClose, isLoading, onModalOpen }: AddPositionProps) => {
  const { formatMessage } = useLocale();
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useFormContext<AddPositionBody>();

  return (
    <>
      <Styled.Button onClick={onModalOpen} variant="contained" color="primary">
        {formatMessage({ id: AppMessages['definitions.positions.add'] })}
      </Styled.Button>

      <Modal
        header={formatMessage({ id: AppMessages['definitions.position.addPosition.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        type="form"
        onSubmit={handleSubmit(onSubmit)}
        submitButtonDisabled={!isValid || isLoading}
      >
        <Styled.Wrapper>
          <Controller
            name="nazwa"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                {...field}
                label={formatMessage({ id: AppMessages['definitions.position.details.name'] })}
                placeholder={formatMessage({ id: AppMessages['definitions.position.details.name.placeholder'] })}
                errorMessage={error?.message}
                required
              />
            )}
          />
        </Styled.Wrapper>
      </Modal>
    </>
  );
};
