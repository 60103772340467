import { useQuery } from 'shared-ui';

import { QueryKeyEnum } from 'core/global.enum';
import { fetchTerminals } from 'api/terminals/terminals';
import { GroupTerminalsPermissionsIdSortEnum } from 'context/query/queryContext/QueryContext.enum';

import { SequencesTableHeader } from './SequencesTableHeader';
import { SequencesTableHeaderContainerProps } from './SequencesTableHeader.types';

export const SequencesTableHeaderContainer = ({ expandedServer }: SequencesTableHeaderContainerProps) => {
  const { data: terminals } = useQuery(
    [QueryKeyEnum.TERMINALS, expandedServer?.id],
    () => fetchTerminals(expandedServer?.id),
    {
      args: {
        zaakceptowane: true,
        sort: [GroupTerminalsPermissionsIdSortEnum.ASC],
      },
      enabled: !!expandedServer,
    },
  );

  return <SequencesTableHeader terminals={terminals?.data || []} />;
};
