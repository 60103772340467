import styled from '@emotion/styled';

export const TopBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ExtraBarElements = styled.div`
  display: flex;
  width: 100%;
`;

export const ElementsWrapper = styled.div`
  display: flex;
`;
