"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePageLayoutStyles = void 0;
const react_1 = require("react");
const PageLayoutStylesContext_1 = require("../../context/pageLayoutStyles/pageLayoutStylesContext/PageLayoutStylesContext");
const usePageLayoutStyles = () => {
    const context = (0, react_1.useContext)(PageLayoutStylesContext_1.PageLayoutStylesContext);
    const pageLayoutStylesWhenNoContextAvailable = {
        headerHeight: 0,
        paddingBottom: 0,
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
    };
    if (context === undefined) {
        return pageLayoutStylesWhenNoContextAvailable;
    }
    return context;
};
exports.usePageLayoutStyles = usePageLayoutStyles;
