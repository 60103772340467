import { fetchTerminals } from 'api/terminals/terminals';
import { QueryKeyEnum } from 'core/global.enum';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { useQuery } from 'hooks/useQuery/useQuery';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { useSavedPagination } from 'hooks/useSavedPagination/useSavedPagination';

import { Terminals } from './Terminals';
import { TerminalsContainerProps } from './Terminals.types';

export const TerminalsContainer = ({ setTerminalsCount, expandedServer }: TerminalsContainerProps) => {
  const {
    storedQueries: { [QueryKeyLocalStorageEnum.TERMINALS_WITH_SEQUENCE]: terminalsQuery },
  } = useLocaleStoreQuery();

  const { rowsPerPage, storedQueryWithoutPagination, offset } = useSavedPagination({
    queryKey: QueryKeyLocalStorageEnum.TERMINALS_WITH_SEQUENCE,
  });

  const { data: terminals, isInitialLoading } = useQuery(
    [QueryKeyEnum.TERMINALS_WITH_SEQUENCE, expandedServer?.id, terminalsQuery],
    () => fetchTerminals(expandedServer?.id),
    {
      args: {
        ...storedQueryWithoutPagination,
        limit: rowsPerPage,
        offset,
        sekwencja: true,
        zaakceptowane: true,
      },
      onSuccess: (response) => {
        expandedServer && setTerminalsCount(response.count);
      },
      enabled: !!expandedServer,
    },
  );

  return <Terminals terminals={terminals?.data} isLoading={isInitialLoading} />;
};
