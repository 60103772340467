"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextWrapper = exports.ListItemText = exports.Icon = exports.ListItem = exports.DRAWER_LIST_ITEM_MARGIN_BOTTOM = exports.DRAWER_LIST_ITEM_HEIGHT = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const styled_components_1 = __importStar(require("styled-components"));
exports.DRAWER_LIST_ITEM_HEIGHT = 54;
exports.DRAWER_LIST_ITEM_MARGIN_BOTTOM = 5;
exports.ListItem = (0, styled_components_1.default)(({ ...props }) => (0, jsx_runtime_1.jsx)(material_1.ListItem, { ...props }))(({ theme: { palette, shape }, $noHighlight }) => (0, styled_components_1.css) `
    margin-bottom: ${exports.DRAWER_LIST_ITEM_MARGIN_BOTTOM}px;
    padding: 0px;
    border-radius: ${shape.borderRadius}px;
    color: ${palette.info.main};

    &:hover {
      background-color: ${$noHighlight ? 'inherit' : palette.action.selected};
    }

    &.active {
      background-color: ${$noHighlight ? 'inherit' : palette.action.selected};
      color: ${$noHighlight ? palette.info.main : palette.common.white};
    }

    &:last-child {
      margin-bottom: 0;
    }
  `);
exports.Icon = (0, styled_components_1.default)(material_1.ListItemIcon)(({ $isOpen }) => (0, styled_components_1.css) `
    align-items: center;
    justify-content: center;
    width: ${$isOpen ? '40px' : '82px'};
    transition: 0.2s linear;
    padding: 15px 0;
  `);
exports.ListItemText = (0, styled_components_1.default)(({ className, ...props }) => ((0, jsx_runtime_1.jsx)(material_1.ListItemText, { ...props, classes: { root: className, primary: 'primary' }, primaryTypographyProps: { style: { whiteSpace: 'normal' } } })))(({ $isOpen }) => (0, styled_components_1.css) `
    padding-right: 15px;
    display: flex;
    align-items: center;
    height: 41px;

    & .primary {
      opacity: ${$isOpen ? 1 : 0};
      transition: opacity 0.2s linear;
      font-weight: 600;
      pointer-events: ${$isOpen ? 'auto' : 'none'};
      min-width: 180px;
    }
  `);
exports.TextWrapper = styled_components_1.default.div(({ $isOpen }) => (0, styled_components_1.css) `
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 180px;
    visibility: ${$isOpen ? 'visible' : 'hidden'};
  `);
