import { useAuth, useLocale } from 'shared-ui';

import { AppRoute } from 'routing/AppRoute.enum';
import { AppMessages } from 'i18n/messages';

export type DefinitionBarRoute = {
  path:
    | AppRoute.definitions
    | AppRoute.positions
    | AppRoute.eventsExportFormats
    | AppRoute.ownersControl
    | AppRoute.display
    | AppRoute.logotype
    | AppRoute.sequencesTerminals;
  label: string;
  isVisible: boolean;
};

export const useTopBarRoutes = () => {
  const { formatMessage } = useLocale();
  const { isAdmin } = useAuth();

  const routes: Record<
    'definitions' | 'positions' | 'events' | 'control' | 'display' | 'logotype' | 'sequences',
    DefinitionBarRoute
  > = {
    definitions: {
      path: AppRoute.definitions,
      label: formatMessage({ id: AppMessages['definitions.organization'] }),
      isVisible: true,
    },
    positions: {
      path: AppRoute.positions,
      label: formatMessage({ id: AppMessages['definitions.positions'] }),
      isVisible: true,
    },
    events: {
      path: AppRoute.eventsExportFormats,
      label: formatMessage({ id: AppMessages['definitions.events'] }),
      isVisible: isAdmin,
    },
    control: {
      path: AppRoute.ownersControl,
      label: formatMessage({ id: AppMessages['definitions.control'] }),
      isVisible: true,
    },
    display: {
      path: AppRoute.display,
      label: formatMessage({ id: AppMessages['definitions.display.title'] }),
      isVisible: true,
    },
    logotype: {
      path: AppRoute.logotype,
      label: formatMessage({ id: AppMessages['definitions.logotype.title'] }),
      isVisible: true,
    },
    sequences: {
      path: AppRoute.sequencesTerminals,
      label: formatMessage({ id: AppMessages['definitions.sequences.title'] }),
      isVisible: true,
    },
  };

  return { routes };
};
