import { useState } from 'react';
import { useLocation, useWindowSize } from 'react-use';
import {
  DRAWER_CLOSE_WIDTH,
  DRAWER_OPEN_WIDTH,
  useDrawer,
  ARROW_BUTTON_WIDTH,
  usePageLayoutStyles,
  useWindowUtils,
  DETAILS_DRAWER_WIDTH,
} from 'shared-ui';

import { useTopBarRoutes } from 'app/definitions/hooks/useTopBarRoutes';
import { TopBarPaginationContext } from '../topBarPaginationContext/TopBarPaginationContext';
import { LINK_WIDTH } from 'app/definitions/definitionsBar/DefinitionsBar';
import { useSelectedPosition } from 'hooks/useSelectedPosition/useSelectedPosition';
import { useSelectedOrganization } from 'hooks/useSelectedOrganization/useSelectedOrganization';

import { TopBarPaginationContextControllerProps } from './TopBarPaginationContextController.types';

const MIN_RIGHT_BAR_SPACE_WIDTH = 570;

export const TopBarPaginationContextController = ({ children }: TopBarPaginationContextControllerProps) => {
  const { width: windowWidth } = useWindowSize();
  const { routes } = useTopBarRoutes();
  const { isDrawerOpen } = useDrawer();
  const { isDesktopView } = useWindowUtils();
  const { selectedPosition } = useSelectedPosition();
  const { selectedOrganization } = useSelectedOrganization();
  const location = useLocation();

  const { paddingLeft, paddingRight } = usePageLayoutStyles();

  const [indexOffset, setIndexOffset] = useState(0);

  const drawerWidth = isDesktopView ? (isDrawerOpen ? DRAWER_OPEN_WIDTH : DRAWER_CLOSE_WIDTH) : 0;
  const detailsWidth =
    (!!selectedPosition && location.pathname === routes.positions.path) ||
    (!!selectedOrganization && location.pathname === routes.definitions.path)
      ? DETAILS_DRAWER_WIDTH
      : 0;
  const visibleRoutes = Object.values(routes).filter((route) => route.isVisible);
  const totalItems = visibleRoutes.length;

  const routesAvailableSpace =
    windowWidth -
    drawerWidth -
    paddingLeft -
    paddingRight -
    detailsWidth -
    MIN_RIGHT_BAR_SPACE_WIDTH -
    ARROW_BUTTON_WIDTH * 2;

  const routesPerPage = Math.max(Math.floor(routesAvailableSpace / LINK_WIDTH), 1);

  const isLeftArrowVisible = indexOffset > 0;
  const isRightArrowVisible = indexOffset + routesPerPage < totalItems;

  const currentPageRoutes = visibleRoutes.slice(indexOffset, indexOffset + routesPerPage);

  const onClickLeftArrow = () => setIndexOffset((prev) => Math.max(0, prev - 1));
  const onClickRightArrow = () => setIndexOffset((prev) => Math.min(totalItems - routesPerPage, prev + 1));

  return (
    <TopBarPaginationContext.Provider
      value={{ currentPageRoutes, isLeftArrowVisible, isRightArrowVisible, onClickLeftArrow, onClickRightArrow }}
    >
      {children}
    </TopBarPaginationContext.Provider>
  );
};
